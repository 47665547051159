import { render, staticRenderFns } from "./FormTagDetails.vue?vue&type=template&id=1ffa6a36&scoped=true"
import script from "./FormTagDetails.vue?vue&type=script&lang=js"
export * from "./FormTagDetails.vue?vue&type=script&lang=js"
import style0 from "./FormTagDetails.vue?vue&type=style&index=0&id=1ffa6a36&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ffa6a36",
  null
  
)

export default component.exports