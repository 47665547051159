<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <FormTagDetails :id="$route.params.id" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FormTagDetails from '@/components/FormTags/FormTagDetails.vue';
export default {
  name: 'FormTagDetailPage',
  components: {
    FormTagDetails,
  },
};
</script>

<style lang="scss" scoped></style>
