<template>
  <v-card :key="reload">
    <v-card-title class="msaBlue white--text">
      {{ title }}
    </v-card-title>
    <v-form ref="form">
      <v-card-text class="py-0">
        <v-container class="pb-0 px-0" fluid>
          <v-row dense>
            <v-col cols="12"> Name </v-col>
            <v-col cols="12">
              <v-text-field
                class="py-0"
                dense
                required
                counter
                maxlength="1000"
                outlined
                v-model="tag.name"
                :rules="rules.name"
                data-testid="form-tag-name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12"> Description </v-col>
            <v-col cols="12">
              <v-textarea
                class="py-0"
                dense
                height="60px"
                counter
                maxlength="2000"
                outlined
                v-model="tag.description"
                data-testid="description-name"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12"> Group </v-col>
            <v-col cols="12">
              <CustomizedAutoComplete
                :value="tag.tagGroupId"
                :items="tagGroups"
                :rules="rules.required"
                @change="tag.tagGroupId = $event"
              />
            </v-col>
            <v-col cols="12" v-if="isEditMode"> Archive </v-col>
            <v-col cols="12" v-if="isEditMode">
              <v-switch
                v-model="tag.isArchive"
                outlined
                dense
                hide-details
                class="mt-0"
                :disabled="!isEditMode"
              >
              </v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>
    <v-card-actions class="pa-0">
      <v-container class="px-4" fluid>
        <v-row>
          <v-col align="right">
            <v-btn class="red white--text mr-5" @click="close()">
              Cancel
            </v-btn>
            <v-btn
              class="msaBlue white--text"
              :disabled="disableSave"
              @click="validate()"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'FormTagDetails',
  props: {
    id: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      tagGroups: [],
      tag: {
        id: -1,
        name: '',
        description: '',
        tagGroupId: null,
        isArchive: false,
        cats: null,
      },
      original: {},
      rules: {
        name: [(v) => !!v.toString().trim() || 'This field is required.'],
        required: [(v) => !!v || 'This field is required'],
      },
      reload: false,
    };
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.tag) === JSON.stringify(this.original) ||
        this.tag.name.trim().length == 0 ||
        !this.tag.tagGroupId
      );
    },
    isEditMode() {
      return this.tag.id != -1;
    },
    title() {
      return this.isAddingTagOnTheFly ? 'Add Tag' : this.tag.name;
    },
    isAddingTagOnTheFly() {
      return this.$route.name != 'FormTagDetail';
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    save() {
      let url =
        this.tag.id == -1 ? 'add-tag?format=json' : 'update-tag?format=json';

      const params = {
        loaderText: 'Saving....',
        tagId: this.tag.id,
        name: this.tag.name,
        description: this.tag.description,
        isArchive: this.tag.isArchive,
        tagGroupId: this.tag.tagGroupId,
      };
      this.$axios.post(url, params).then((response) => {
        if (this.isAddingTagOnTheFly) {
          const tag = response.data;
          this.$emit('add', {
            id: tag.id,
            val: tag.val,
            disabled: false,
            deletedAt: '',
          });
          this.close();
        } else {
          this.$router.push({
            name: 'FormTags',
          });
        }
      });
    },
    close() {
      if (this.isAddingTagOnTheFly) {
        this.$emit('close');
      } else {
        this.$router.push({ name: 'FormTags' });
      }
    },
    setData(data) {
      this.tag = data;
      this.original = JSON.parse(JSON.stringify(this.tag));
      if (!this.isAddingTagOnTheFly) {
        this.$store.commit('setSelectedTagName', this.tag.name);
        this.$store.commit('updateCrumbRefresh');
      }
    },
    getTagGroups() {
      this.$axios.post('get-tag-groups?format=json', {}).then((response) => {
        this.tagGroups = response.data;
      });
    },
    getTag() {
      const params = {
        tagId: this.id,
      };
      this.$axios
        .post('get-tag?format=json', params)
        .then((response) => {
          this.setData(response.data);
        })
        .catch((error) => {
          if (error.response.status == 404 && !this.isAddingTagOnTheFly) {
            this.$router.push({ name: 'FormTags' });
          }
        });
    },
    loadUrlParams() {
      if (isNaN(parseInt(this.$route.params.id))) {
        this.$router.push({ name: 'FormTags' });
      }
      if (parseInt(this.$route.params.id) > 0) {
        this.getTag();
      }
    },
  },
  beforeMount() {
    this.getTagGroups();
    if (!this.isAddingTagOnTheFly) {
      this.loadUrlParams();
    }
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
